import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../components/Hero'
import RenderContent from '../components/RenderContent'
import Facts from '../components/Facts'
import Article from '../components/Article'
import Section from '../components/Section'
import Journey from '../components/Journey'
import Button from '../components/Button'

import imageHelper from '../helpers/image-helper'
import getVesselsV2 from '../helpers/get-vessels-v2'

const VESSEL_GROUPS = {
  ship: ['flag', 'call_sign'],
  capacity: ['capacity', 'reefer_plugs', 'cranes'],
  dimensions: ['length', 'beam', 'draught', 'gross_tonnage', 'deadweight'],
}

const VESSEL_LABELS = {
  flag: 'Flag',
  call_sign: 'Call sign',
  capacity: 'Capacity',
  reefer_plugs: 'Reefer plugs',
  cranes: 'Cranes',
  length: 'Length',
  beam: 'Beam (width)',
  draught: 'Draught (depth)',
  gross_tonnage: 'Gross Tonnage',
  deadweight: 'Deadweight',
}

export default class VesselTemplate extends Component {
  state = {
    vessel: null,
    table: null,
  }

  async componentDidMount () {
    const { imo } = this.props.data.ievv.vessel
    const vessels = await getVesselsV2()
    const vessel = vessels.find(item => `${item.imo}` === imo)
    this.setState({ vessel })
  }

  getFacts = () => {
    const { vessel } = this.state
    const { ievv } = this.props.data

    const getGroupFacts = group => {
      return VESSEL_GROUPS[group].map(key => {
        const match = ievv.vessel.facts.find(({ type }) => type === key)

        return {
          label: VESSEL_LABELS[key],
          value: match.value || '—',
        }
      })
    }
    return [
      {
        title: 'Ship',
        info: [
          { label: 'IMO Number', value: vessel ? vessel.imo : 'Loading...' },
          ...getGroupFacts('ship'),
        ],
      },
      {
        title: 'Capacity',
        info: getGroupFacts('capacity'),
      },
      {
        title: 'Dimensions',
        info: getGroupFacts('dimensions'),
      },
    ]
  }

  render () {
    const { vessel } = this.state
    const { data } = this.props
    const { title, heroImage } = data.ievv
    const getImage = imageHelper(data)
    const hero = heroImage ? getImage(heroImage) : null

    const heroProps = vessel
      ? {
        coordinates: [
          {
            image: hero,
            title: vessel.name,
            preamble: vessel.trade,
            position: [vessel.longitude, vessel.latitude],
            primary: true,
          },
        ],
      }
      : { title }

    return (
      <>
        <Hero {...heroProps} map />

        <Facts facts={this.getFacts()} />

        <Section type="light" small>
          <Button secondary arrow={false} to="/ports-and-routes/schedule/">
            See full schedule
          </Button>
        </Section>

        <Article>
          <RenderContent data={data} />
        </Article>
      </>
    )
  }
}

VesselTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($pageId: String!) {
    ievv(id: { eq: $pageId }) {
      ...ArticleData
    }
    ...GetImages
  }
`
