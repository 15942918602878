import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Container from '../Container'

const bem = new BEMHelper('facts')

export default function Facts ({ facts }) {
  return (
    <article {...bem('')}>
      <Container nodeType="div" {...bem('content')}>
        {facts.map(({ title, info }, index) => (
          <section key={index} {...bem('section')}>
            <h2 {...bem('title')}>{title}</h2>

            <div {...bem('info')}>
              {info.map(({ label, value }) => (
                <dl {...bem('item')} key={label + value}>
                  <dt {...bem('label')}>{label}</dt>
                  <dd {...bem('value')}>{value}</dd>
                </dl>
              ))}
            </div>
          </section>
        ))}
      </Container>
    </article>
  )
}

Facts.propTypes = {
  facts: PropTypes.array.isRequired,
}
