import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { addDays, setHours, setMinutes, format } from 'date-fns'
import BEMHelper from 'react-bem-helper'
import Icon from '../Icon'

const bem = new BEMHelper('journey')

export default class Journey extends Component {
  constructor (props) {
    super(props)

    this.state = {
      expanded: props.expanded || false,
    }
  }

  toggle = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render () {
    const { expanded } = this.state
    const { items, title, eta, showHeader, tight } = this.props

    return (
      <section {...bem('', { tight })}>
        {showHeader && (
          <header {...bem('summary')}>
            {eta && (
              <p {...bem('intro')}>
                Estimated arrival:{' '}
                <time {...bem('eta')}>{format(eta, 'D. MMM, HH:mm')}</time>
              </p>
            )}
            {title && <h2 {...bem('title')}>{title}</h2>}
          </header>
        )}

        {expanded && (
          <ol {...bem('list')}>
            {items.map(({ date, status, info }, index) => {
              const isActive = status === 'active'

              return (
                <li {...bem('item', status)} key={index}>
                  <time {...bem('point')}>
                    {!isActive && (
                      <span {...bem('date', 'large')}>
                        {format(date, 'D. MMM')}
                      </span>
                    )}
                    {!isActive && (
                      <span {...bem('date')}>{format(date, 'HH:mm')}</span>
                    )}
                    {isActive && (
                      <span {...bem('indicator')}>
                        <Icon icon="shipfront" />
                      </span>
                    )}
                    <span {...bem('progress')} />
                  </time>

                  <p {...bem('info')}>
                    {isActive ? (
                      <Link to="/ports-and-routes/schedule/">{info}</Link>
                    ) : (
                      info
                    )}
                  </p>
                </li>
              )
            })}
          </ol>
        )}

        {!this.props.expanded && (
          <button
            type="button"
            {...bem('toggle', { expanded })}
            onClick={this.toggle}
          >
            {expanded ? 'Hide details' : 'Show details'}
          </button>
        )}
      </section>
    )
  }
}

Journey.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  eta: PropTypes.object,
  status: PropTypes.string,
  statusText: PropTypes.string,
  showHeader: PropTypes.bool,
  tight: PropTypes.bool,
}

// TODO: What's `ATS` ? (Rumba departures)
// TODO(2): Add `ETA`? (Everything after active)
Journey.defaultProps = {
  title: '4 Containers: 32650, 37514, 52813, 16881',
  eta: setMinutes(setHours(addDays(new Date(), 1), 21), 30),
  status: 'on-time',
  statusText: 'On schedule',
  showHeader: true,
  items: [
    {
      status: 'done',
      date: setMinutes(setHours(addDays(new Date(), -2), 5), 10),
      info: 'Fetched on pick up point, Brucherhaben 123, DE 641354',
    },
    {
      status: 'done',
      date: setMinutes(setHours(addDays(new Date(), -2), 15), 32),
      info: 'Arrive on port Rotterdam (CTA)',
    },
    {
      status: 'done',
      date: setMinutes(setHours(addDays(new Date(), -2), 21), 14),
      info: 'Loaded on boat Rumba (Waybill)',
    },
    {
      status: 'done',
      date: setMinutes(setHours(addDays(new Date(), -2), 23), 8),
      info: 'Rumba departures',
    },
    {
      status: 'active',
      date: new Date(),
      info: 'See location of Rumba now',
    },
    {
      status: 'eta',
      date: setMinutes(setHours(addDays(new Date(), 1), 18), 0),
      info: 'Port Ålesund (SKU)',
    },
    {
      status: 'eta',
      date: setMinutes(setHours(addDays(new Date(), 1), 21), 30),
      info: 'Destination Sandvikvegen 12A, NO 6000, Sandefjord',
    },
  ],
}
